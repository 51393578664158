import { createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import orange from "@material-ui/core/colors/orange";
import blue from "@material-ui/core/colors/blue";
const theme = createMuiTheme({
    palette: {
        primary: indigo,
        secondary: blue,
    },
    direction: 'ltr',
    typography: {
        fontFamily: "'Roboto', sans-serif",
    },
    // overrides: {
    //     MuiToolbar: {
    //         gutters: {
    //             paddingLeft: '16px',
    //             paddingRight: '16px',
    //             [defaultTheme.breakpoints.up('sm')]: {
    //                 paddingLeft: '16px',
    //                 paddingRight: '16px',
    //             },
    //         },
    //     },
    // },
});

export default theme;