import React, {useEffect, useState} from 'react';
import {getUser, redirectToLogin} from "../helpers/auth";
import {firebaseApp} from "../config/firebase";

export const AuthContext = React.createContext({
  user: undefined,
  authError:undefined,
  showLogin: () => {},
  logout: () => {}

});


export default props => {
  const [user , setUser] = useState() ;
  const [authError , setAuthError] = useState();

  const showLogin = async ()=>{
    await redirectToLogin().then(function(result : any) {
      setUser(result.user) ;
    }).catch(function(error) {
      setAuthError(error.message);
    }) ;
  }
  const logout = async ()=>{
    await firebaseApp.auth().signOut()
    setUser(null);
  }

  const determineLoginState = async () => {
    try{
      const _user = await getUser() ;
      if(_user){
        setUser(_user);
      }
      else{
        await showLogin()
      }
    }
    catch (e) {
      setAuthError(e.message) ;
    }
  } ;

  useEffect(()=>{
    determineLoginState() ;
  } , [user])

  return (
    <AuthContext.Provider value={{user,authError,showLogin,logout}}>
      {props.children}
    </AuthContext.Provider>
  );
};
