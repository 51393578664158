import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import theme from './style/theme';
import {create} from 'jss';
import {ThemeProvider} from '@material-ui/core/styles';
import {StylesProvider, jssPreset} from '@material-ui/core/styles';
import AuthProvider from './context/auth-context' ;

const jss = create({
    ...jssPreset(),
    // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
    insertionPoint: 'jss-insertion-point',
});

ReactDOM.render(
  <React.StrictMode>
      <AuthProvider>
          <ThemeProvider theme={theme}>
              <StylesProvider jss={jss}>
                  <App />
              </StylesProvider>
          </ThemeProvider>
      </AuthProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
