import React, {useContext, useEffect, useState} from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import { Box,Button, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {Link} from "react-router-dom"
import Switch from '@material-ui/core/Switch';
import {ADD_MESSAGE_ROUTE, EDIT_MESSAGE_ROUTE} from "../../../routes";
import {getMessages, updateMessageTemplate} from "../../../helpers/messageCenter";
import moment from 'moment';
import "./Messages.css" ;
import {AuthContext} from "../../../context/auth-context";
import {canUserWrite} from "../../../helpers/auth";

const Messages = ({history}) => {

    const user = useContext(AuthContext).user;

    const [messagesList , setMessagesList] = useState([]) ;
    const [loading , setLoading] = useState(true) ;

    useEffect( ()=>{
        console.log("Messages did mount");
        (async ()=> {
            const messages = await getMessages();
            setMessagesList(messages) ;
            setLoading(false)
        })()
        return ()=>{
            console.log("Messages did unmount")
        };
    } ,[]);

    const updateMessageInDb = async (templateId,newData) =>{
        await updateMessageTemplate(templateId , newData)
    }

    const updateMessage = (message) =>{
        const index = findIndexByTemplateId(message.templateId)
        if(index !== -1) {
            const _messagesList = [...messagesList]
            _messagesList[index] = {..._messagesList[index], ...message}
            setMessagesList(_messagesList)
        }
    }

    const findIndexByTemplateId = (templateId)=>{
        return messagesList.findIndex((message)=>message.templateId == templateId) ;
    }

    return (
        <div className={"messages-list"}>
            {
                // @ts-ignore
               !loading && <MaterialTable
                    title="Messages List"
                    columns={[
                        {
                            title: "Active",
                            field: "active",
                            id: "active",
                            render: rowData => <Switch
                                checked={rowData.active}
                                onChange={async (event)=>{
                                    const checked = (event.target.checked)
                                    await updateMessageInDb(rowData.templateId,{active:checked})
                                    updateMessage({...rowData , active:checked})
                                }
                                }
                                name="checkedB"
                                color="primary"
                            />
                        },
                        {
                            title: "name",
                            field: "displayName",
                            id: "displayName",
                            render: rowData => <Link to={EDIT_MESSAGE_ROUTE.replace(":id" , rowData.id)}><Typography variant={"subtitle1"} style={{color:"#0073aa"}}>{rowData.displayName}</Typography></Link>
                        },
                        {
                            title: "Template ID",
                            field: "templateId",
                            id: "templateId",
                            align: "left",
                        },
                        {
                            title: "Latest Version",
                            field: "version",
                            id: "version",
                            align: "left",
                        },
                        {
                            title: "Created At",
                            field: "createdAt",
                            id: "createdAt",
                            align: "left",
                            render: rowData => moment(rowData.createdAt).format("DD-MM-YYYY")
                        },
                        {
                            title: "Updated At",
                            field: "updatedAt",
                            id: "createdAt",
                            align: "left",
                            render: rowData => moment(rowData.updatedAt).format("DD-MM-YYYY")
                        },
                        {
                            title: "Status",
                            field: "status",
                            id: "status",
                            align: "left",
                        },
                    ]}
                    data={messagesList}
                    localization={{
                        toolbar: {
                            searchTooltip: "search",
                            searchPlaceholder: "search",
                        },

                        body: {
                            emptyDataSourceMessage: "No Messages In DataBase"
                        },
                        header: {
                            actions: "",
                        },
                    }}
                    options={{
                        //   toolbar: false,
                        searchFieldVariant: "outlined",
                        searchFieldSize: "small",
                        search: true,
                        paging: true,
                        pageSizeOptions : [10, 20, 100],
                        sorting: true,

                        pageSize: 10,
                        showTitle: false,
                        searchFieldAlignment: "left",
                        detailPanelType: "single",
                        actionsColumnIndex: 10,
                    }}
                    components={{
                        Container: (props :any) => <Box {...props} />,
                        Toolbar: (props : any) => (
                            <div style={{position:"relative"}}>
                                {
                                    canUserWrite(user.data.role) && (
                                        <Button
                                            style={{position:"absolute" , 'right' : "5px" , top : "10px" , zIndex:5}}
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            endIcon={<AddIcon />}
                                            onClick={(e) => {
                                                e.preventDefault();

                                                history.push(ADD_MESSAGE_ROUTE) ;

                                            }}
                                        >
                                            Add New{" "}
                                        </Button>
                                    )
                                }
                                <MTableToolbar {...props} />
                            </div>
                        ),
                    }}
                />
            }
        </div>

    );
};



export default Messages;