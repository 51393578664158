import React, {useContext, useEffect, useState} from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import { Box,Button, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {Link} from "react-router-dom"
import {ADD_CATEGORY_ROUTE, EDIT_CATEGORY_ROUTE} from "../../../routes";
import {getCategories, getMessages} from "../../../helpers/messageCenter";
import moment from 'moment';
import "./Categories.css" ;
import {AuthContext} from "../../../context/auth-context";
import {canUserWrite} from "../../../helpers/auth";

const Categories = ({history}) => {

    const user = useContext(AuthContext).user;

    const [categoriesList , setCategories] = useState([]) ;
    const [loading , setLoading] = useState(true) ;

    useEffect( ()=>{
        console.log("Categories did mount");
        (async ()=> {
            const messages = await getCategories();
            // @ts-ignore
            setCategories(messages) ;
            setLoading(false)
        })()
        return ()=>{
            console.log("Categories did unmount")
        };
    } ,[]);

    return (
        <div className={"categories-list"}>
            {
                // @ts-ignore
             !loading && <MaterialTable
                    title="Categories List"
                    columns={[
                        {
                            title: "name",
                            field: "name",
                            id: "name",
                            render: rowData => <Link to={EDIT_CATEGORY_ROUTE.replace(":id" , rowData.id)}><Typography variant={"subtitle1"} style={{color:"#0073aa"}}>{rowData.name}</Typography></Link>
                        },
                        {
                            title: "Created At",
                            field: "createdAt",
                            id: "createdAt",
                            align: "left",
                            render: rowData => moment(rowData.createdAt).format("DD-MM-YYYY")
                        },
                        {
                            title: "Updated At",
                            field: "updatedAt",
                            id: "createdAt",
                            align: "left",
                            render: rowData => moment(rowData.updatedAt).format("DD-MM-YYYY")
                        },
                    ]}
                    data={categoriesList}
                    localization={{
                        toolbar: {
                            searchTooltip: "search",
                            searchPlaceholder: "search",
                        },

                        body: {
                            emptyDataSourceMessage: "No Messages In DataBase"
                        },
                        header: {
                            actions: "",
                        },
                    }}
                    options={{
                        //   toolbar: false,
                        searchFieldVariant: "outlined",
                        searchFieldSize: "small",
                        search: true,
                        paging: true,
                        pageSizeOptions : [10, 20, 100],
                        sorting: true,

                        pageSize: 10,
                        showTitle: false,
                        searchFieldAlignment: "left",
                        detailPanelType: "single",
                        actionsColumnIndex: 10,
                    }}
                    components={{
                        Container: (props :any) => <Box {...props} />,
                        Toolbar: (props : any) => (
                            <div style={{position:"relative"}}>
                                {
                                    canUserWrite(user.data.role) && (
                                        <Button
                                            style={{position:"absolute" , 'right' : "5px" , top : "10px" , zIndex:5}}
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            endIcon={<AddIcon />}
                                            onClick={(e) => {
                                                e.preventDefault();

                                                history.push(ADD_CATEGORY_ROUTE) ;

                                            }}
                                        >
                                            Add New{" "}
                                        </Button>
                                    )
                                }
                                <MTableToolbar {...props} />
                            </div>
                        ),
                    }}
                />
            }
        </div>

    );
};



export default Categories;