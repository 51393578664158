import * as firebase from "firebase" ;
let firebaseConfig ;
if(process.env.REACT_APP_FIREBASE_ENV === 'erez'){
    firebaseConfig = {
        apiKey: "AIzaSyAHHLejkgJSRuAWJurWh0Q8xFHRzjxHUT0",
        authDomain: "erez-a7db9.firebaseapp.com",
        databaseURL: "https://erez-a7db9.firebaseio.com",
        projectId: "erez-a7db9",
        storageBucket: "erez-a7db9.appspot.com",
        messagingSenderId: "164639137722",
        appId: "1:164639137722:web:2863f6297df6b4e3604783"
    };
}
else if(process.env.REACT_APP_FIREBASE_ENV === 'preprod'){
    firebaseConfig = {
        apiKey: "AIzaSyALKIgti7dwYpbspAMt3BtVplEEAsFirqQ",
        authDomain: "preproduction-4b513.firebaseapp.com",
        databaseURL: "https://preproduction-4b513.firebaseio.com",
        projectId: "preproduction-4b513",
        storageBucket: "preproduction-4b513.appspot.com",
        messagingSenderId: "465430736163",
        appId: "1:465430736163:web:cc6e34b33e39f6086bb096",
        measurementId: "G-LESLBHFE04"
    };
}
else if(process.env.REACT_APP_FIREBASE_ENV === 'production'){
    firebaseConfig = {
        apiKey: "AIzaSyAD_dWwV1oaTUSV_TrLDG2g3PVpFK-gBkU",
        authDomain: "production-d68ae.firebaseapp.com",
        databaseURL: "https://production-d68ae.firebaseio.com",
        projectId: "production-d68ae",
        storageBucket: "production-d68ae.appspot.com",
        messagingSenderId: "1058060832091",
        appId: "1:1058060832091:web:0fc578f0e9dc3d6c3c1e7e",
        measurementId: "G-WSKRRTX6L4"
    };
}
else{
    throw new Error("no config for firebase declared")
}

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore() ;
export const firebaseStorage = firebase.storage() ;
export const firebaseApp = firebase ;