import React, {Fragment, useContext} from 'react';
import { Divider } from "@material-ui/core";
import {
    MESSAGES_ROUTE,
    USERS_ROUTE,
    CATEGORIES_ROUTE,
    MESSAGES_MENU_MATCH,
    CATEGORIES_MENU_MATCH,
    USERS_MENU_MATCH
} from '../../../routes' ;
import MenuList from "@material-ui/core/MenuList";
import SidebarMenuItem from "./SidebarMenuItem/SidebarMenuItem" ;
import {AuthContext} from "../../../context/auth-context";
import {isAdmin} from "../../../helpers/auth";
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';

const SidebarMenu : React.FC<{}> = () => {

    const user = useContext(AuthContext).user ;


    return (
        <Fragment>
            <MenuList>
                <SidebarMenuItem  items={[{matchHighlight: MESSAGES_MENU_MATCH , to:MESSAGES_ROUTE , primary:'Message Center', icon:<MessageOutlinedIcon  />}]} />
                <SidebarMenuItem  items={[{matchHighlight: CATEGORIES_MENU_MATCH ,to:CATEGORIES_ROUTE , primary:'Categories', icon:<CategoryOutlinedIcon/>}]} />
            </MenuList>
            <Divider/>
            <MenuList>
                {
                    isAdmin(user) && (
                        <SidebarMenuItem  items={[{matchHighlight:USERS_MENU_MATCH,to:USERS_ROUTE , primary:'Users', icon:<PeopleOutlinedIcon />}]} />
                    )
                }
            </MenuList>
        </Fragment>
    );
};

export default SidebarMenu;