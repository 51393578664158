import React, {useEffect} from 'react';
import Layout from "../../components/Layout/Layout";
import {Route, Switch} from "react-router-dom";
import {
    MESSAGES_ROUTE,
    ADD_MESSAGE_ROUTE,
    EDIT_MESSAGE_ROUTE,
    USERS_ROUTE,
    ADD_CATEGORY_ROUTE,
    EDIT_CATEGORY_ROUTE, CATEGORIES_ROUTE
} from "../../routes";
import Messages from './Messages/Messages';
import Message from './Message/Message';
import Category from "./Category/Category";
import Categories from "./Categories/Categories";
import Users from './Users/Users'
export default function Admin({history}){
    return (
        <Layout>
            <Switch>
                <Route key={history.location.pathname} exact path={USERS_ROUTE} component={Users}/>
                <Route key={history.location.pathname} exact path={ADD_MESSAGE_ROUTE} component={Message}/>
                <Route key={history.location.pathname} exact path={EDIT_MESSAGE_ROUTE} component={Message}/>
                <Route exact path={MESSAGES_ROUTE} component={Messages}/>
                <Route key={history.location.pathname} exact path={ADD_CATEGORY_ROUTE} component={Category}/>
                <Route key={history.location.pathname} exact path={EDIT_CATEGORY_ROUTE} component={Category}/>
                <Route exact path={CATEGORIES_ROUTE} component={Categories}/>
                <Route path='/' component={Messages}/>
            </Switch>
        </Layout>
    );
}
