import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {enCountryCode, languages} from "../../config";
import ReactCountryFlag from "react-country-flag";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        boxShadow: "none",
        backgroundColor: "transparent"
    },
    indicator:{
        backgroundColor: "transparent"
    },
    tabSelected:{
        borderBottom: "3px solid #3f51b5 !important"
    },
    flexContainer: { flexWrap: "wrap"},
    tabRoot: {
        backgroundColor: "#f5f5f5",
        flexGrow: "initial", minWidth: "110px" , border: "1px solid #fff" ,
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)" }
});

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

interface IProps{
    onTabChange? : (newValue) => {}
}

const TabList : React.FC<IProps> = ({onTabChange}) =>  {
    const classes = useStyles();
    const [value, setValue] = React.useState(enCountryCode);

    const handleChange = (event, newValue) => {
        onTabChange(newValue);
        setValue(newValue);
    };

    const tabs =  languages.map((language, index)=> {
        return <Tab classes={{root: classes.tabRoot , selected:classes.tabSelected}} value={language.countryCode} key={language.countryCode}
                    label={language.name} icon={<div style={{display: "flex", alignItems: "center"}}>
            <ReactCountryFlag svg countryCode={language.iconCode} style={{
                fontSize: '1.5em'
            }}/>
        </div>} {...a11yProps(language.countryCode)} />;
    });

    return (
        <Paper square className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                textColor="primary"
                aria-label="icon tabs example"
                classes={{ flexContainer: classes.flexContainer , indicator:classes.indicator }}
            >
                {
                    tabs
                }
            </Tabs>
        </Paper>
    );
}

export default TabList;