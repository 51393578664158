import React, {useContext, useEffect, useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress , Backdrop  } from '@material-ui/core';
import * as Yup from "yup";
import {getCategory, updateCategory, uploadImageToServer} from "../../../helpers/messageCenter";
import {
    ICategory,
} from "../../../types";
import {EDIT_CATEGORY_ROUTE} from "../../../routes";
import { withRouter } from 'react-router'
import {canUserWrite} from "../../../helpers/auth";
import {AuthContext} from "../../../context/auth-context";
import TextField from "@material-ui/core/TextField";
import UploadFile from "../../../components/Form/UploadFile/UploadFile";
import Typography from "@material-ui/core/Typography";

const validation = Yup.object().shape({
    name: Yup.string().trim().required("category name id is required"),
});


function getInitialUploadImageData() {
    return {file: undefined , uploadImageLocalFile:undefined} ;
}


const initialCategoryData : ICategory = {
    name: "",
    image:"",
    createdAt:0,
    updatedAt:0
}


const Category = ({match , history}) => {
    const user = useContext(AuthContext).user ;
    const isEdit = !!match.params.id ;
    const classes = useStyles();
    const [loading , setLoading] = useState(true) ;
    const [validationErrors, setValidationErrors] = useState([]) ;
    const [formSubmit , setFormSubmit] = useState(false) ;
    const [uploadImage , setUploadImage] = useState(getInitialUploadImageData()) ;
    const [categoryData , setCategoryData] = useState(initialCategoryData) ;

    const disableForm = ()=>{
        return !user || !canUserWrite(user.data.role)
    }

    const loadForm = async () => {
        console.log("load form");
        if(isEdit){
            const id = match.params.id ;
            //todo:
            const categoryData = await getCategory(id) as any;
            setCategoryData(categoryData)
            setLoading(false);
        }
        else{
            setLoading(false)
        }
    } ;

    useEffect( ()=>{
        console.log("did mount");
        (async ()=> {
            await loadForm();
        })()
        return ()=>{
            console.log("did unmount")
        };
    } ,[]);

    useEffect(()=>{
        //todo:
        console.log("did update")
        setValidationErrors([]) ;
    } , [categoryData])



    function onContentFormChange(value, name){
        if(name === 'uploadImage'){
            setUploadImage({file:value.file , uploadImageLocalFile: value.uploadImageLocalFile}) ;
        }
        else{
            setCategoryData({...categoryData,[name]:value})
        }
    }


    function getUploadImageLocalFile(){
        return uploadImage.uploadImageLocalFile ;
    }


    async function formSubmitHandler() {

        try{
            await validation.validate(categoryData) ;
            let imageUrl = categoryData.image ;
            if(uploadImage.file){
                imageUrl = await uploadImageToServer(uploadImage.file);
            }
            else if(uploadImage.uploadImageLocalFile === 'delete'){
                imageUrl = ""
            }

            const currentTime = new Date().getTime() ;

            const createdAt = (categoryData.createdAt == 0) ? currentTime : categoryData.createdAt;

            let _categoryData : ICategory = {
                ...categoryData,
                image:imageUrl,
                updatedAt: currentTime,
                createdAt,
            }

            _categoryData = await updateCategory(_categoryData,isEdit );

            if (!isEdit) {
                history.push(EDIT_CATEGORY_ROUTE.replace(":id" , _categoryData.id)) ;
            }
            else{
                setTimeout(()=>{
                    setUploadImage(getInitialUploadImageData());
                    setCategoryData(_categoryData)
                    setFormSubmit(false);
                },1000)
            }
        }
        catch (e) {
            if(e.name === 'ValidationError'){
                setValidationErrors([...validationErrors , {field: e.path , message : e.message}]) ;
            }
            setFormSubmit(false);
        }
    }

    function getValidationErrorMsg(field){
        const index = validationErrors.findIndex((e)=>e.field === field)
        if(index === -1) return '';
        return  validationErrors[index].message ;
    }


    return (<>
            <div style={{maxWidth:"700px" , marginLeft:"50px"}}>
                {   !loading &&
                <div>
                    <Backdrop className={classes.backdrop} open={formSubmit} classes={{root: classes.backdropRoot}}>
                        <CircularProgress color="secondary" />
                    </Backdrop>
                    <div style={{marginBottom:"30px"}}>
                        <Typography variant="h1" noWrap style={{fontSize:"20px" , fontWeight:400}}>{isEdit ? "Edit Category" : "Add New Category"}</Typography>
                    </div>
                    <div style={{display: "flex", marginTop: "20px" , marginRight: "30px", maxWidth: "100%"}}>
                        <div style={{flexGrow: 1}}>
                            <div>
                                <TextField
                                    type="text"
                                    label="Category Name"
                                    fullWidth
                                    disabled={disableForm()}
                                    margin="normal"
                                    error={!!getValidationErrorMsg("name")}
                                    helperText={getValidationErrorMsg("name")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={categoryData.name}
                                    variant="outlined"
                                    onChange={(e) =>  onContentFormChange(e.target.value , "name")}
                                />
                                <br />

                                <p>Image</p>
                                <div style={{ display: "flex", alignItems: "flex-end" , marginBottom:"30px"}}>
                                    <div style={{width:"100%"}}>
                                        <UploadFile disableForm={disableForm()} uploadImageLocalFile={getUploadImageLocalFile()} initialImagePath={categoryData.image} onFileChange={(file)=> {onContentFormChange(file,"uploadImage"); return true;} }/>
                                    </div>
                                </div>
                                {
                                    canUserWrite(user.data.role) && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={formSubmit}
                                            onClick={async ()=> {
                                                setFormSubmit(true) ;
                                                await formSubmitHandler();
                                            }}
                                            style={{marginTop:"50px",width: "100%"}}
                                        >
                                            {"Save"}
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </>


    );
};

// @ts-ignore
const useStyles = makeStyles((theme) => ({
    backdropRoot:{
        zIndex:5
    },
    leftToRightInput: {
        flip: false, // opt-out of conversion for a specific rule-set
        direction: "ltr",
        textAlign: "right",
    },
    radioGroup: {
        flexDirection: "row",
    },

    uploadButton: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderStyle: "dashed",
        fontWeight: "400",
    },

    uploadButtonLabel: {
        justifyContent: "space-between",
    },
    select: {
        marginTop: theme.spacing(3),
    },
    cardRoot: {
        //  marginTop: theme.spacing(2),
        width: "100%",
        backgroundColor: "transparent",
        boxShadow: "none",
        //  border: `1px solid ${theme.palette.grey["400"]}`
    },
    cardDetails: {
        display: "flex",
        //   flexDirection: 'column'
    },
    cardImg: {
        objectFit: "contain",
        width: "auto",
        borderRadius: theme.spacing(0.5),
    },
    cardSelector: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        paddingRight: `${theme.spacing(2)}px !important`,
    },

    cardContent: {
        width: "100%",
        padding: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "&:last-child": {
            paddingBottom: 0,
        },
    },
    cardCloseIcon: {
        padding: theme.spacing(0.5),
    },

    autocomplete: {
        //  marginTop: theme.spacing(2),
    },
    autocompleteInput: {
        padding: theme.spacing(2, 4),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        // marginTop: theme.spacing(1),
    },

    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    spinnerRoot: {
        height: "100vh",
    },
}));

export default withRouter(Category);

