import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsIcon from "@material-ui/icons/Settings";
import SettingsDialog from "./SettingsDialog/SettingsDialog";

export default function SettingsMenu({templateId}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [settingsMenuId, setSettingsMenuId] = useState("");
    const [settingsMenuName, setSettingsMenuName] = useState("");


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        debugger;
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuItemClicked = (e) => {
        setSettingsMenuName(e.target.textContent);
        setSettingsMenuId(e.target.id);
        handleClose();
    } ;

    return (
        <div>
            <SettingsDialog templateId={templateId} onModalClose={()=>{setSettingsMenuId(''); setSettingsMenuName(''); return true;}} id={settingsMenuId} name={settingsMenuName} modalOpen={!!settingsMenuId}/>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <SettingsIcon style={{color:"#3f51b5"}}/>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem id="testMessage" onClick={(e)=> menuItemClicked(e)}>Test Message</MenuItem>
            </Menu>
        </div>
    );
}