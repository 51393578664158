import React, {useContext, useEffect, Fragment, useState} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import NoImage from '../../../assets/images/NoImgUploaded.gif' ;

const UploadFile : React.FC<{initialImagePath : string , uploadImageLocalFile: string | undefined, onFileChange : (file) => {} , disableForm:boolean}> = (props)  => {

    const classes = useStyles();
    const [imagePath , setImagePath] = useState(props.initialImagePath ?? '') ;


    return <Fragment>
        {
            props.uploadImageLocalFile !== 'delete' &&
            (props.disableForm || imagePath || props.uploadImageLocalFile) ?
            (
            <Card className={classes.cardRoot}>
                <div className={classes.cardDetails}>
                    <CardContent className={classes.cardContent}>
                        <CardMedia
                            classes={classes.cardImg}
                            component="img"
                            alt="Message Image"
                            image={
                                props.uploadImageLocalFile || imagePath|| NoImage
                            }
                            title="Contemplative Reptile"
                        />
                    </CardContent>
                    {!props.disableForm &&
                    <IconButton
                        onClick={() => {
                            setImagePath("")
                            props.onFileChange({file: undefined , uploadImageLocalFile: "delete"});
                        }}
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        className={classes.cardCloseIcon}
                    >
                        <CloseIcon fontSize="small" color="action" />
                    </IconButton>
                    }
                </div>
            </Card>
        )

        :
            ( <Fragment>
                <input
                    accept="image/*"
                    className={classes.input}
                    style={{ display: "none" }}
                    id="image-button-file"
                    multiple
                    name="image"
                    onChange={(event) => {
                        let reader = new FileReader();
                        let file = event.target.files[0];
                        reader.onloadend = () => {
                            // @ts-ignore
                            setImagePath(reader.result);
                            props.onFileChange({file: file , uploadImageLocalFile: reader.result});
                        };
                       reader.readAsDataURL(file);
                    }}
                    type="file"
                />
                <label htmlFor="image-button-file">
                    <Button
                        variant="outlined"
                        size="large"
                        endIcon={<ImageIcon />}
                        component="span"
                        classes={{
                            root: classes.uploadButtonLabel,
                            label: classes.uploadButtonLabel,
                        }}
                        className={classes.uploadButton}
                    >
                        Upload Image
                    </Button>
                </label>
            </Fragment>)
        }

    </Fragment>
}

// @ts-ignore
const useStyles = makeStyles((theme) => ({
    uploadButton: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderStyle: "dashed",
        fontWeight: "400",
        textTransform: "none",
        width:"100%",
        height:"150px"
    },

    uploadButtonLabel: {
        justifyContent: "space-between",
    },
    select: {
        marginTop: theme.spacing(3),
    },
    cardRoot: {
        //  marginTop: theme.spacing(2),
        width: "350px",
        height:"300px",
        backgroundColor: "transparent",
        boxShadow: "none",
        //  border: `1px solid ${theme.palette.grey["400"]}`
    },
    cardDetails: {
        width: "350px",
        height:"300px",
        display: "flex",
        //   flexDirection: 'column'
    },
    cardImg: {
        width: "350px",
        height:"300px",
        borderRadius: theme.spacing(0.5),
        backgroundColor:"red"
    },
    cardSelector: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        paddingRight: `${theme.spacing(2)}px !important`,
    },

    cardContent: {
        width: "350px",
        height:"300px",
        padding: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "&:last-child": {
            paddingBottom: 0,
        },
    },
    cardCloseIcon: {
        padding: theme.spacing(0.5),
        cursor:"pointer",
        "&:hover" :{
            backgroundColor:"transparent"
        }
    },

    autocomplete: {
        //  marginTop: theme.spacing(2),
    },
    autocompleteInput: {
        padding: theme.spacing(2, 4),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        // marginTop: theme.spacing(1),
    },

    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    spinnerRoot: {
        height: "100vh",
    },
}));

export default UploadFile ;