import {firebaseApp} from "../config/firebase";
import {firestore} from "../config/firebase";

export function canUserWrite(role){
    return ["admin" , "editor"].indexOf(role) !== -1
}

export function isAdmin(user){
    return user.data.role === 'admin' ;
}

async function updateLoggedInUser(user) {
    const data = {email:user.email , lastLogin:new Date().getTime(), photoUrl: user.photoURL} ;
    try{
        await firestore.collection("users").doc(user.email).get() ;
        await firestore.collection("users").doc(user.email).update(data)

    }
    catch (e) {
        await firestore.collection("users").doc(user.email).set({...data, name : user.displayName , role:"viewer" })
    }
}

export const getUser =  ()=>{
    return new Promise(async function(resolve, reject) {
        let authChanged = false ;

        const redirectResult = await  firebaseApp.auth().getRedirectResult();
        if(redirectResult && redirectResult.user){
            await updateLoggedInUser(redirectResult.user);
            // await firestore.collection("users").doc(redirectResult.user.email).set({email:redirectResult.user.email , lastLogin:new Date().getTime()})
        }

        firebaseApp.auth().onAuthStateChanged(async function(user) {
            if(authChanged){
                return ;
            }
            authChanged=true;
            if (user) {
               if(user.email.includes('bittech.co.il')){
                   // @ts-ignore
                   const doc = await firestore.collection("users").doc(user.email).get() ;

                   // @ts-ignore
                    user.data = doc.data() ;

                   // User is signed in.
                    resolve(user) ;
                }
                else{
                    await firebaseApp.auth().signOut()
                    reject({message:"Email Not Registered to system"});
                }
            }
            else{
                resolve(user) ;
            }

        });
    });

} ;

export const redirectToLogin = async ()=>{
    return new Promise((resolve,reject)=>{
        const provider = new firebaseApp.auth.GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: 'select_account'
        });
        firebaseApp.auth().signInWithRedirect(provider);
    })

}