import React, {Fragment} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Paper, {PaperProps} from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import {makeStyles} from "@material-ui/core/styles";


const AppDialog : React.FC<{onClose:()=>{} , titleText : string, contentText : string, buttonText:string , disableBackdropClick:boolean|undefined}> = (props) => {
    const classes = useStyles();

    return (
        <Dialog
            open={true}
            disableBackdropClick={true}
            onClose={props.onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                {props.titleText}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.contentText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color={"primary"}>
                    {props.buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

function PaperComponent(props: PaperProps) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

// @ts-ignore
const useStyles = makeStyles((theme) => ({
    backdropRoot:{
        zIndex:5
    }
}))

export default AppDialog;