import React, {useContext} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {CircularProgress, CssBaseline} from '@material-ui/core';
import Admin from "./screens/Admin/Admin";
import AppDialog from "./components/AppDialog/AppDialog";

import {AuthContext} from "./context/auth-context";

const App = () => {
    const user = useContext(AuthContext).user;
    const authError = useContext(AuthContext).authError;
    const showLogin = useContext(AuthContext).showLogin;

    return (
      <Router>
        <CssBaseline/>
          {
              authError && <AppDialog
                  // @ts-ignore
                  onClose={showLogin}
                  titleText={"Login Error"}
                  contentText={authError}
                  buttonText={"Try Again"}
                  disableBackdropClick={true}/>
          }
        {
              user && !authError ? (<Switch>
                  <Route path='/' component={Admin}/>
              </Switch>) : (<div style={{
                  position: "absolute",
                  top:"50%",
                  left:"50%"
              }}><CircularProgress/></div>)
          }

      </Router>
  );
}

export default App;
