import React, {useContext, useEffect, useState} from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress , Backdrop  } from '@material-ui/core';
import { Prompt } from 'react-router'
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import {enCountryCode, languages} from "../../../config";
import ContentForm from "./ContentForm/ContentForm" ;
import moment from "moment";
import * as Yup from "yup";
import {getCategories, getMessage, updateMessage} from "../../../helpers/messageCenter";
import {
    ICategory,
    IMessage,
    IMessageMetaData,
    IMessageTranslationData,
    IMessageTranslations,
    IMessageVersionData
} from "../../../types";
import {EDIT_MESSAGE_ROUTE} from "../../../routes";
import { withRouter } from 'react-router'
import {canUserWrite} from "../../../helpers/auth";
import {AuthContext} from "../../../context/auth-context";
import TabList from "../../../components/TabList/TabList";
import SettingsMenu from "./SettingsMenu/SettingsMenu";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import FormHelperText from '@material-ui/core/FormHelperText';

const validation = Yup.object().shape({
    templateId: Yup.string().trim().required("template id is required"),
    displayName: Yup.string().trim().required("display name is required"),
    expirationInDays: Yup.number().required("insert days duration of message"),
    destinations: Yup.array().min(1, "template needs at least 1 destination defined"),
});

const initialContentData : IMessageTranslations = {} as any ;
// object of languages and the uploaded image that was uploaded in form

function getInitialTranslationData() : IMessageTranslationData{
    return {
        title :"",
        description:"",
        actionLinkText: "",
        pushMessage : "",
        pushHeading:"",
    } ;
}


for(const lang of languages){
    initialContentData[lang.countryCode] = getInitialTranslationData() ;
}

const initialMessageVersion : IMessageVersionData = {
    expirationInDays : undefined,
    version:1,
    status:"Draft",
    category:undefined,
    destinations : ["message"],
    translations : initialContentData,
    actionLinkUrl: "",
    actionAppScreen: "",
    actionLinkType: "",
    actionLinkTarget: "",
    createdAt:0,
    updatedAt:0
}

const initialMessageMetaData : IMessageMetaData = {
    templateId : "",
    displayName: ""
}

let message : IMessage = {
    ...initialMessageMetaData,
    versions : [initialMessageVersion],
}

let allCategoriesDefault : ICategory[] = []

const Message = ({match , history}) => {
    const user = useContext(AuthContext).user ;
    const isEdit = !!match.params.id ;
    const classes = useStyles();
    const [loading , setLoading] = useState(true) ;
    const [validationErrors, setValidationErrors] = useState([]) ;
    const [formSubmit , setFormSubmit] = useState(false) ;
    const [disableForm , setDisableForm] = useState(false) ;
    const [messageVersion  , setMessageVersion] = useState(initialMessageVersion) ;
    const [activeLanguage , setActiveLanguage] =  useState(languages[0].countryCode) ;
    const [messageMetaData , setMessageMetaData] = useState(initialMessageMetaData) ;
    const [selectedVersion  , setSelectedVersion] = useState(message.versions[0].version) ;
    const [allCategories, setAllCategories] = useState(allCategoriesDefault)


    const switchLanguage = (languageCode)=>{
        // add language if missing in messageVersion translations object
        if(!messageVersion.translations[languageCode]){
            setMessageVersion({...messageVersion , translations : {...messageVersion.translations , [languageCode] : getInitialTranslationData()} })
        }
        setActiveLanguage(languageCode) ;
    } ;


    const loadForm = async () => {
        console.log("load form");
        const allCategories = await getCategories() ;
        setAllCategories(allCategories) ;
        if(isEdit){
            const id = match.params.id ;
            message = await getMessage(id) as any;
            setMessageMetaData({templateId:message.templateId , displayName:message.displayName})
            setMessageVersion(message.versions[0]) ;
            setSelectedVersion(message.versions[0].version)
            setLoading(false);
            setDisableForm(true);
        }
        else{
            setLoading(false)
        }
    } ;

    useEffect( ()=>{
        console.log("did mount");
        (async ()=> {
            await loadForm();
        })()
        return ()=>{
            console.log("did unmount")
        };
    } ,[]);

    useEffect(()=>{
        console.log("did update")
        setValidationErrors([]) ;
    } , [messageVersion, messageMetaData])

    useEffect(()=>{
        if (!disableForm) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }
    } , [disableForm])

    function onContentFormChange(value, name , mainObject=false){
        if(mainObject){
            setMessageVersion({...messageVersion , [name]:value})
        }
        else{
            const languageContentData = {...messageVersion.translations[activeLanguage] , [name] : value}
            const translations = {...messageVersion.translations , [activeLanguage]:languageContentData}
            setMessageVersion({...messageVersion , translations:translations})
        }
    }
    function onTabChange(code){
        switchLanguage(code) ;
        return true ;
    }

    function editButtonListener() {
        if(messageVersion.status === 'Published'){
            const newMessageVersion = {...message.versions[0], version : message.versions[0].version + 1 , status : "Draft" , createdAt:0 , updatedAt:0} ;
            // @ts-ignore
            message = ({...message , versions : [newMessageVersion , ...message.versions]})
            setSelectedVersion(newMessageVersion.version)
            // @ts-ignore
            setMessageVersion(newMessageVersion);
        }
        setDisableForm(false)
    }

    function onVersionChanged(versionNumber) {
        setSelectedVersion(versionNumber);
        const versionObj = message.versions.find(version => version.version == versionNumber ) ;
        setMessageVersion(versionObj);
    }


    async function formSubmitHandler() {

        try{
            await validation.validate({
                templateId: messageMetaData.templateId,
                displayName : messageMetaData.displayName,
                expirationInDays:messageVersion.expirationInDays,
                destinations:messageVersion.destinations,
            }) ;

            const translations = {...messageVersion.translations} ;

            const currentTime = new Date().getTime() ;

            const updatedMessageVersion : IMessageVersionData = {
                ...messageVersion,
                updatedAt: currentTime,
                translations
            }
            updatedMessageVersion.createdAt = (updatedMessageVersion.createdAt == 0) ? currentTime : updatedMessageVersion.createdAt;

            await updateMessage(updatedMessageVersion, messageMetaData , isEdit);

            if (!isEdit) {
                setDisableForm(true);
                history.push(EDIT_MESSAGE_ROUTE.replace(":id" , messageMetaData.templateId)) ;
            }
            else{
                setTimeout(()=>{
                    // replace version object in versions array (in order for it to be updated according to form)
                    const versions = [...message.versions] ;
                    const index = versions.findIndex((version)=> version.version === updatedMessageVersion.version)
                    versions[index] = updatedMessageVersion ;

                    message = {...message , versions : versions}
                    setMessageVersion(updatedMessageVersion);
                    setDisableForm(true);
                    setFormSubmit(false);
                },1000)
            }
        }
        catch (e) {
            if(e.name === 'ValidationError'){
                setValidationErrors([...validationErrors , {field: e.path , message : e.message}]) ;
            }
            setFormSubmit(false);
        }
    }

    function getValidationErrorMsg(field){
        const index = validationErrors.findIndex((e)=>e.field === field)
        if(index === -1) return '';
        return  validationErrors[index].message ;
    }


    return (
        <div>
            <Prompt
                when={!disableForm}
                message='You have unsaved changes, are you sure you want to leave?'
            />
            {   !loading &&
                <div>
                    <Backdrop className={classes.backdrop} open={formSubmit} classes={{root: classes.backdropRoot}}>
                        <CircularProgress color="secondary" />
                    </Backdrop>
                    <div style={{marginBottom:"30px"}}>
                        <Typography variant="h1" noWrap style={{fontSize:"20px" , fontWeight:400}}>{isEdit ? "Edit Message" : "Add New Message"}</Typography>
                    </div>
                    <div style={{display: "flex", marginTop: "20px" , marginRight: "30px", maxWidth: "100%"}}>
                        <div style={{flexGrow: 1, marginRight: "50px"}}>
                            <div style={{maxWidth: "100%", marginBottom:"20px"}}>
                                <TabList onTabChange={onTabChange}/>
                            </div>
                            <ContentForm
                                disableForm={disableForm}
                                msgData={messageVersion.translations[activeLanguage]}
                                messageVersion={messageVersion}
                                onChange={onContentFormChange}
                            />
                        </div>
                        <div style={{minWidth: "300px", textAlign: "center"}}>
                            <Paper variant={"outlined"} elevation={0.8} style={{padding: "20px 10px",position:"relative",marginBottom: "30px"}}>
                                {
                                    canUserWrite(user.data.role) && <div style={{cursor:"pointer",position: "absolute" ,right: "-10px", top: "0"}}>
                                        <SettingsMenu templateId={messageMetaData.templateId}/>
                                    </div>
                                }
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    name="versions"
                                    label="Versions"
                                    value={selectedVersion}
                                    select
                                    disabled={isEdit && !disableForm}
                                    onChange={(event)=> onVersionChanged(event.target.value) }
                                    // align={"left"}
                                    size="small"
                                    style={{margin: "15px 0",textAlign:"left"}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {message.versions.map((item, i) => (
                                        <MenuItem key={i} value={item.version}>
                                            Version {item.version} - {moment(item.createdAt || new Date().getTime()).format("DD-MM-YYYY")}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    name="color"
                                    label="Status"
                                    value={messageVersion.status}
                                    onChange={(e)=> setMessageVersion(
                                        // @ts-ignore
                                        {...messageVersion, status: e.target.value}
                                    )}
                                    disabled={disableForm}
                                    style={{margin: "15px 0" , textAlign:"left"}}
                                    select
                                    // align={"left"}
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {["Draft", "Published"].map((item, i) => (
                                        <MenuItem key={i} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <div style={{margin: "15px 0"}}>
                                    <div style={{display: "flex", justifyContent: "space-between"}}>
                                        <p>Created Date: </p>
                                        <p>{messageVersion.createdAt ? moment(messageVersion.createdAt).format("DD.MM.YY HH:mm") : '-' }</p>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-between"}}>
                                        <p>Last Updated: </p>
                                        <p>{messageVersion.updatedAt  ? moment(messageVersion.updatedAt).format("DD.MM.YY HH:mm") : '-' }</p>
                                    </div>
                                </div>
                                {
                                    canUserWrite(user.data.role) && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={formSubmit}
                                            onClick={(isEdit && disableForm) ? editButtonListener : async ()=> {setFormSubmit(true) ; await formSubmitHandler();}}
                                            style={{width: "80%"}}
                                        >
                                            {isEdit && disableForm ? "Edit" : "Save"}
                                        </Button>
                                    )
                                }
                            </Paper>
                            <Paper variant={"outlined"} elevation={0.8}
                                   style={{padding: "20px 10px"}}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    disabled={isEdit}
                                    name="templateName"
                                    error={!!getValidationErrorMsg("templateId")}
                                    helperText={getValidationErrorMsg("templateId")}
                                    label="Template ID"
                                    size="small"
                                    style={{margin: "15px 0"}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={messageMetaData.templateId}
                                    onChange={(e)=> setMessageMetaData(
                                        // @ts-ignore
                                        {...messageMetaData, templateId: e.target.value || undefined }
                                    )}
                                />
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    disabled={disableForm}
                                    name="displayName"
                                    error={!!getValidationErrorMsg("displayName")}
                                    helperText={getValidationErrorMsg("displayName")}
                                    label="Display Name"
                                    size="small"
                                    style={{margin: "15px 0"}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={messageMetaData.displayName}
                                    onChange={(e)=> setMessageMetaData(
                                        // @ts-ignore
                                        {...messageMetaData, displayName: e.target.value || undefined }
                                    )}
                                >
                                </TextField>
                                <div style={{display:"flex"}}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="number"
                                        error={!!getValidationErrorMsg("expirationInDays")}
                                        helperText={getValidationErrorMsg("expirationInDays")}
                                        disabled={disableForm}
                                        value={messageVersion.expirationInDays}
                                        name="expirationInDays"
                                        label="Expiration (Days)"
                                        size="small"
                                        style={{margin: "15px 5px 15px 0"}}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e)=> {
                                            setMessageVersion(
                                                // @ts-ignore
                                                {...messageVersion, expirationInDays: e.target.value? parseInt(e.target.value) : undefined}
                                            )
                                        }}
                                    >
                                    </TextField>
                                </div>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    name="color"
                                    label="Category"
                                    value={(messageVersion.category && messageVersion.category.id) || ""}
                                    onChange={(e) =>  {
                                        const category = {...allCategories.find(category => category.id === e.target.value)}
                                        delete category.updatedAt ; delete  category.createdAt ;
                                        console.log(category)
                                        setMessageVersion(
                                            // @ts-ignore
                                            {...messageVersion, category}
                                        )
                                    }}
                                    disabled={disableForm}
                                    style={{margin: "15px 0" , textAlign:"left"}}
                                    select
                                    // align={"left"}
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {allCategories.map((item, i) => (
                                        <MenuItem key={i} value={item.id}>
                                            <div style={{display:"flex" , width:"100%",alignItems:"center"}}>
                                                <div style={{flex:"1"}}>{item.name}</div>
                                                <div style={{
                                                    width:"30px",
                                                    height:"30px",
                                                    marginRight:"5px",
                                                    backgroundImage : "url('" + item.image + "')",
                                                    backgroundSize: "cover",
                                                }}/>
                                            </div>
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-mutiple-checkbox-label">Destinations</InputLabel>
                                    <Select
                                        labelId="demo-mutiple-checkbox-label"
                                        id="destinations"
                                        name="destinations"
                                        error={!!getValidationErrorMsg("destinations")}
                                        multiple
                                        style={{textAlign:"left"}}
                                        disabled={disableForm}
                                        value={messageVersion.destinations || []}
                                        onChange={(e)=> {
                                            setMessageVersion(
                                            // @ts-ignore
                                        {...messageVersion, destinations: e.target.value}
                                            )}}
                                        input={<Input />}
                                        renderValue={(selected) => (selected as string[]).join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {["message","push"].map((name) => (
                                            <MenuItem key={name} value={name}>
                                                <Checkbox checked={messageVersion.destinations && messageVersion.destinations.indexOf(name) > -1} />
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {getValidationErrorMsg("destinations") && <FormHelperText style={{color:"red"}}>{getValidationErrorMsg("destinations")}</FormHelperText>}
                                </FormControl>
                            </Paper>

                        </div>

                    </div>
                </div>
            }
        </div>

    );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

// @ts-ignore
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
    },
    backdropRoot:{
        zIndex:5
    },
    leftToRightInput: {
        flip: false, // opt-out of conversion for a specific rule-set
        direction: "ltr",
        textAlign: "right",
    },
    radioGroup: {
        flexDirection: "row",
    },

    uploadButton: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderStyle: "dashed",
        fontWeight: "400",
    },

    uploadButtonLabel: {
        justifyContent: "space-between",
    },
    select: {
        marginTop: theme.spacing(3),
    },
    cardRoot: {
        //  marginTop: theme.spacing(2),
        width: "100%",
        backgroundColor: "transparent",
        boxShadow: "none",
        //  border: `1px solid ${theme.palette.grey["400"]}`
    },
    cardDetails: {
        display: "flex",
        //   flexDirection: 'column'
    },
    cardImg: {
        objectFit: "contain",
        width: "auto",
        borderRadius: theme.spacing(0.5),
    },
    cardSelector: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        paddingRight: `${theme.spacing(2)}px !important`,
    },

    cardContent: {
        width: "100%",
        padding: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "&:last-child": {
            paddingBottom: 0,
        },
    },
    cardCloseIcon: {
        padding: theme.spacing(0.5),
    },

    autocomplete: {
        //  marginTop: theme.spacing(2),
    },
    autocompleteInput: {
        padding: theme.spacing(2, 4),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        // marginTop: theme.spacing(1),
    },

    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    spinnerRoot: {
        height: "100vh",
    },
}));

export default withRouter(Message);

