import React, {useContext, useEffect, useState} from "react";
import {Redirect} from "react-router-dom" ;
import MaterialTable, { MTableToolbar } from "material-table";
import {Box} from "@material-ui/core";
import moment from 'moment';
import "../Messages/Messages.css" ;
import {canUserWrite, getUser} from "../../../helpers/auth";
import {getUsers, saveUser} from "../../../helpers/users";
import {makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import {AuthContext} from "../../../context/auth-context";

const Users = ()=>{
    const user = useContext(AuthContext).user ;
    const classes = useStyles();

    const [columns] = useState([
        {
            title: "",
            field: "photoURL",
            id: "photoURL",
            editable:"never",
            render: rowData => {
                return (
                    <div>
                        <Avatar
                            alt="Profile Picture" src={rowData.photoUrl}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            className={classes.avatarImage}/>
                    </div>
                )
            }
        },
        {
            title: "name",
            field: "name",
            id: "name"
        },
        {
            title: "email",
            field: "email",
            id: "email",
            editable:"never"
        },
        {
            title: "Last Login",
            field: "lastLogin",
            id: "lastLogin",
            align: "left",
            editable:"never",
            render: rowData => {
                return  moment(rowData.lastLogin).format("DD-MM-YYYY HH:mm");
            }

        },
        {
            title: "role",
            field: "role",
            id: "role",
            lookup: { admin: 'admin', editor: 'editor' , viewer:"viewer" }
        }
    ]);

    const [users, setUsers] = useState([]);


    useEffect( ()=>{
        (async ()=> {
            const _users = await getUsers() ;
            setUsers(_users);
        })()
    } ,[]);

    function updateUser(user){
        const index = getUserIndexByEmail(user.email) ;
        if(index !== -1){
            const _users  = [...users] ;
            _users[index] = {..._users[index] , ...user} ;
            setUsers(_users);
        }
    }

    function getUserIndexByEmail(email) {
        return users.findIndex((user)=>user.email == email) ;
    }


    return (
        <div className={"messages-list"}>
            {
                !canUserWrite(user.data.role) && <Redirect to="/" />
            }
            {
                // @ts-ignore
                <MaterialTable
                    title="Users List"
                    editable={{
                        onRowUpdate: async (newData, oldData) =>{
                            await saveUser(newData);
                            updateUser(newData);

                        }
                    }}
                    columns={columns}
                    data={users}
                    localization={{
                        toolbar: {
                            searchTooltip: "search",
                            searchPlaceholder: "search",
                        },

                        body: {
                            emptyDataSourceMessage: "No Users In DataBase"
                        },
                        header: {
                            actions: "",
                        },
                    }}
                    options={{
                        //   toolbar: false,
                        searchFieldVariant: "outlined",
                        searchFieldSize: "small",
                        search: true,
                        paging: true,
                        pageSizeOptions : [10, 20, 100],
                        sorting: true,

                        pageSize: 10,
                        showTitle: false,
                        searchFieldAlignment: "left",
                        detailPanelType: "single",
                        actionsColumnIndex: 10,
                    }}
                    components={{
                        Container: (props :any) => <Box {...props} />,
                        Toolbar: (props : any) => (
                            <div style={{position:"relative"}}>
                                <MTableToolbar {...props} />
                            </div>
                        ),
                    }}
                />
            }
        </div>

    );
}

const useStyles = makeStyles((theme) => ({
    backdropRoot:{
        zIndex:1000
    },
    avatarImage: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    }
}));

export default Users ;