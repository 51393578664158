import {NavLink as RouterLink, useLocation} from "react-router-dom";
import React, {useContext} from "react";
import MenuItem from "@material-ui/core/MenuItem";
import {ListItemIcon, ListItemText} from "@material-ui/core";
import * as PropTypes from "prop-types";
import {ISidebarMenuItemProps} from "../../../../types";
import blue from "@material-ui/core/colors/blue";

const SidebarMenuItem : React.FC<ISidebarMenuItemProps> = ({items}) => {
    const location = useLocation();

    const renderLink = React.useMemo(
        // @ts-ignore
        () => React.forwardRef((itemProps, ref) => <RouterLink to={items[0].to} ref={ref} {...itemProps} />),
        [items],
    );

    function isMainSelected(pathName : string):boolean{
        const item = items.find((item) => {
            return (item.matchHighlight === "/" + pathName.split("/")[1]) ;
        })
        return (typeof item !== "undefined")
    }

    function renderSubItems(){
        let itemsJsx = [] ;
        for(const item of items){
            // @ts-ignore
            const renderLink = React.forwardRef((itemProps, ref) => <RouterLink to={item.to} ref={ref} {...itemProps} />) ;
            itemsJsx.push(
                <MenuItem style={{paddingLeft:"30px"}} button component={renderLink} selected={item.to === location.pathname}>
                    {item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
                    <ListItemText primary={item.primary} />
                </MenuItem>
            );
        }
        return itemsJsx ;
    }

    return (
        <div>
            <div>
                <MenuItem button component={renderLink} selected={isMainSelected(location.pathname)}>
                    {items[0].icon ? <ListItemIcon>{items[0].icon}</ListItemIcon> : null}
                    <ListItemText primary={items[0].mainMenuName || items[0].primary} />
                </MenuItem>
            </div>
        </div>
    );
}

export default SidebarMenuItem;

