import React, {useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import {Backdrop, Button, CircularProgress} from "@material-ui/core";
import {addMessageToCustomer} from "../../../../../../helpers/messageCenter";
import * as Yup from "yup";
import { Alert, AlertTitle } from '@material-ui/lab';
import {makeStyles} from "@material-ui/core/styles";

const validation = Yup.object().shape({
    email: Yup.string().trim().email().required("email is required")
});

export default function TestMessage({templateId}) {

    const classes = useStyles();
    const [email , setEmail] = useState();
    const [metaData , setMetadata] = useState();
    const [validationErrors, setValidationErrors] = useState([]);
    const [formSubmit , setFormSubmit] = useState(false) ;
    const [generalError, setGeneralError] = useState();
    const [successMessage, setSuccessMessage] = useState();


    function getValidationErrorMsg(field){
        const index = validationErrors.findIndex((e)=>e.field === field)
        if(index === -1) return '';
        return  validationErrors[index].message ;
    }

    useEffect(()=>{
        setValidationErrors([]) ;
        setSuccessMessage("") ;
    } , [email, metaData]) ;

    async function submitForm() {
        try{
            setFormSubmit(true);
            setGeneralError("") ;
            setSuccessMessage("") ;

            await validation.validate({
                email: email
            }) ;
            let _metaData = {} ;
            if(metaData){
                let metaList = metaData.split("&") ;
                for(const metaItem of metaList){
                    const metaValues = metaItem.split("=") ;
                    if(metaValues.length==2){
                        _metaData[metaValues[0]] = metaValues[1] ;
                    }
                    else{
                        setValidationErrors([...validationErrors , {field: "metadata" , message : "missing equal sign"}]) ;
                        setFormSubmit(false);
                        return ;
                    }
                }
            }
            await addMessageToCustomer(email , _metaData , templateId);
            setFormSubmit(false);
            setSuccessMessage("message successfully sent!") ;

        }
        catch (e) {
            if(e.name === 'ValidationError'){
                setValidationErrors([...validationErrors , {field: e.path , message : e.message}]) ;
            }
            else{
                setGeneralError(e.message) ;
            }
            setFormSubmit(false);
        }
    }

    return (
        <div>
            {
                generalError &&  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {generalError}
                </Alert>
            }
            {
                successMessage &&  <Alert severity="success">
                    {successMessage}
                </Alert>
            }
            {
                <Backdrop open={formSubmit} classes={{root: classes.backdropRoot}}>
                    <CircularProgress color="secondary" />
                </Backdrop>
            }
            <TextField
                fullWidth
                variant="outlined"
                type="text"
                name="email"
                error={!!getValidationErrorMsg("email")}
                helperText={getValidationErrorMsg("email")}
                label="Customer Email"
                size="small"
                style={{margin: "15px 0"}}
                InputLabelProps={{
                    shrink: true,
                }}
                value={email}
                onChange={(e)=> setEmail(e.target.value)}
            />
            <TextField
                fullWidth
                variant="outlined"
                type="text"
                name="metaData"
                error={!!getValidationErrorMsg("metadata")}
                helperText={getValidationErrorMsg("metadata")}
                label="Message MetaData"
                size="small"
                placeholder={"e.g brand=investous&full_name=moshe levy"}
                style={{margin: "15px 0"}}
                InputLabelProps={{
                    shrink: true,
                }}
                value={metaData}
                onChange={(e)=> setMetadata(e.target.value)}
            />
            <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                onClick={submitForm}
            >
                {"Submit"}
            </Button>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    backdropRoot:{
        zIndex:5
    }
}));