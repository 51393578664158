import {firestore} from "../config/firebase";
export async function getUsers(){
    const users = [] ;
    const userDocuments = await firestore.collection('users').get();
    userDocuments.forEach(function(doc) {
        users.push(doc.data()) ;
    });
    return users ;
}

export async function saveUser(user) {
    await firestore.collection("users").doc(user.email).update({name:user.name , role:user.role}) ;
}