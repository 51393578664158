import {firestore , firebaseStorage} from "../config/firebase";
import {ICategory, IMessage, IMessageMetaData, IMessageVersionData,ITemplateData} from "../types";

export const updateMessage = async (messageVersion : IMessageVersionData , message: IMessageMetaData , isEdit:boolean) => {
    const messageData = {displayName: message.displayName} ;
    if(!isEdit){
        await firestore.collection("mc.templates").doc(message.templateId).set(messageData) ;
    }
    else{
        await firestore.collection("mc.templates").doc(message.templateId).update(messageData) ;
    }

    // update template version document
    await firestore.collection("mc.templates").doc(message.templateId).collection("versions").doc(messageVersion.version.toString()).set(messageVersion)

    return message.templateId ;
}

export const updateMessageTemplate = async(templateId , templateData : ITemplateData) => {
    await firestore.collection("mc.templates").doc(templateId).update(templateData)
}

export async function uploadImageToServer(uploadImage){
    const storageRef = firebaseStorage.ref("content/uploads/" + uploadImage.name) ;
    const snapshot = await storageRef.put(uploadImage);
    return  await snapshot.ref.getDownloadURL();
}

export async function getMessage(templateId){
    const messageDocument = await firestore.collection('mc.templates').doc(templateId).get();
    const versionsDocuments = await firestore.collection('mc.templates').doc(templateId).collection('versions').orderBy("createdAt" , "desc").get();
    let message : IMessage = {versions : [] , templateId: messageDocument.id , displayName: messageDocument.data().displayName};
    // get all version documents of templateId
    versionsDocuments.forEach(function(doc) {
        const data = doc.data();
        // @ts-ignore
        message.versions.push(data)
    });
    return message ;
}

export async function getMessages(){
    const messages : any  = {} ;
    const messagesList : any[] = [] ;
    const templatesDocuments = await firestore.collection('mc.templates').get();
    templatesDocuments.forEach(function(doc) {
        // @ts-ignore
        messages[doc.id] = {displayName:doc.data().displayName , templateId:doc.id , active: (typeof doc.data().active === "undefined") ? true : doc.data().active}
    });
    for(const templateId in messages){
        const versionsDocuments = await firestore.collection('mc.templates').doc(templateId).collection('versions').orderBy("createdAt" , "desc").limit(1).get();
        versionsDocuments.forEach(function(doc) {
            // always 1 iteration because of limit 1 in results
            const data = doc.data();
            messages[templateId] = {...messages[templateId] , ...data} ;
        });
        messagesList.push({...messages[templateId] , id : templateId}) ;
    }
    messagesList.sort((a ,b) => b.createdAt - a.createdAt) ;
    return messagesList ;
}

export async function addMessageToCustomer(email , metaData , templateId) {
    const querySnapshot = await firestore.collection("customers").where("email", "==" ,email).limit(1).get() ;
    let customer;
    let id;
    let template
    querySnapshot.forEach((doc)=>{
        customer=doc.data();
        id = doc.id ;
    });
    if(!id){
        throw new Error("Customer doesn't exists in firebase, Make sure customer received at least on real message before")
    }

    const templateSnapshot = await firestore.collection("mc.templates").doc(templateId).collection("versions").orderBy("createdAt" , "desc").limit(1).get() ;
    templateSnapshot.forEach((doc)=>{
        template=doc.data();
    });
    const currentTimestamp = new Date().getTime();
    if(template){
        await firestore.collection("customers").doc(id).collection("messages").add({
            templateId:templateId,
            version:template.version,
            metadata:metaData,
            status:"new",
            expirationDate: currentTimestamp + template.expirationInDays*24*60*60*1000,
            createdAt: currentTimestamp,
            origin:"tests"
        }) ;
    }
    return true;
}

export async function getCategories() : Promise<ICategory[]>{
    const result : ICategory[] = []
    const querySnapshot = await firestore.collection('mc.categories').get();
    querySnapshot.forEach((doc) => {
        // @ts-ignore
        result.push({
            id:doc.id,
            ...doc.data()
        })
    });
    return result
}

export async function getCategory(id){
    const response = await firestore.collection('mc.categories').doc(id).get();
    let category : ICategory
    if(response){
        // @ts-ignore
        category = response.data()
    }
    if(category){
        category.id = id
    }
    return category ;
}

export const updateCategory = async (data : ICategory, isEdit:boolean) => {
    if(!isEdit){
        const docRef = await firestore.collection("mc.categories").doc() ;
        data.id = docRef.id
        docRef.set(data)
    }
    else{
        await firestore.collection("mc.categories").doc(data.id).update(data) ;
        delete data.createdAt ; delete data.updatedAt ;

        // update all mc.templates version documents that contain this category.id
        const querySnapshot =  await firestore.collection("mc.templates").get()
        querySnapshot.forEach((doc) => {
            firestore.collection("mc.templates").doc(doc.id).collection("versions").where("category.id" , "==" , data.id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(versionDoc => {
                        firestore.collection("mc.templates").doc(doc.id).collection("versions").doc(versionDoc.id).update({category:data}) ;
                    });
                });
        });
    }
    console.log(data)
    return data ;
}