import React, {Fragment, useContext} from "react";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {AuthContext} from "../../../../context/auth-context";
import Avatar from "@material-ui/core/Avatar";
import {makeStyles} from "@material-ui/core/styles";
const UserMenu = () => {
    const classes = useStyles();
    const user = useContext(AuthContext).user
    const logout = useContext(AuthContext).logout
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Fragment>
            {user && (
                <div>
                    {
                        user.photoURL ?
                            (<Avatar
                                alt="Profile Picture" src={user.photoURL}
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                className={classes.avatarImage}
                                onClick={handleMenu}/>)
                            :
                            ( <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>)
                    }

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={logout}>Logout</MenuItem>
                        {/*<MenuItem onClick={handleClose}>My account</MenuItem>*/}
                    </Menu>
                </div>
            )}
        </Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    avatarImage: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginLeft:theme.spacing(2)
    }
}));

export default UserMenu ;