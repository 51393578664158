import React, {useState} from 'react';
import {Editor}  from '@tinymce/tinymce-react';
import TextField from '@material-ui/core/TextField';
import {mobileAppScreens, TINY_MCE_API_KEY} from "../../../../config";
import {uploadImageToServer} from "../../../../helpers/messageCenter";
import LanguageDialog from "../../../../components/LanguageDialog/LanguageDialog";
import MenuItem from "@material-ui/core/MenuItem";


const ContentForm =
    ({onChange, msgData , disableForm, messageVersion}) => {

    const updateChanges = (value,field,mainObject=false) =>{
        onChange(value,field,mainObject);
        return;
    }

    return (
        <div>
            <TextField
                type="text"
                label="Title"
                fullWidth
                disabled={disableForm}
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                value={msgData.title}
                variant="outlined"
                onChange={(e) =>  updateChanges(e.target.value , "title")}
            />
            <br />
            <TextField
                type="text"
                label="Description"
                fullWidth
                disabled={disableForm}
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                value={msgData.description}
                variant="outlined"
                multiline
                inputProps={{
                    style: {
                        minHeight: "150px"
                    },
                }}
                onChange={(e) =>  updateChanges(e.target.value , "description")}
            />
            <div style={{display:"flex" , justifyContent:"space-between" , width:"100%"}}>
                <TextField
                    type="text"
                    label="Action Link URL"
                    disabled={disableForm}
                    style={{width:"30%"}}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={messageVersion.actionLinkUrl}
                    variant="outlined"
                    onChange={(e) =>  updateChanges(e.target.value , "actionLinkUrl" , true)}
                />
                <TextField
                    type="text"
                    label="Action App Screen"
                    disabled={disableForm}
                    style={{width:"30%"}}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={messageVersion.actionAppScreen}
                    variant="outlined"
                    select
                    onChange={(e) =>  updateChanges(e.target.value , "actionAppScreen",true)}>
                    {["none",...mobileAppScreens].map((item, i) => (
                        <MenuItem key={i} value={item === "none" ? "" : item}>
                            {item}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    type="text"
                    label="Action Link Text"
                    disabled={disableForm}
                    style={{width:"20%"}}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={msgData.actionLinkText}
                    variant="outlined"
                    onChange={(e) =>  updateChanges(e.target.value , "actionLinkText")}
                />
                <TextField
                    type="text"
                    label="Action Link Type"
                    style={{width:"20%"}}
                    disabled={disableForm}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={messageVersion.actionLinkType}
                    variant="outlined"
                    select
                    onChange={(e) =>  updateChanges(e.target.value , "actionLinkType",true)}
                >
                    {[{label: "Button" , value:"button"}, {label: "Anchor" , value:"anchor"}].map((item, i) => (
                        <MenuItem key={i} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    type="text"
                    label="Action Link Target"
                    style={{width:"19%"}}
                    disabled={disableForm}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={messageVersion.actionLinkTarget}
                    variant="outlined"
                    select
                    onChange={(e) =>  updateChanges(e.target.value , "actionLinkTarget",true)}
                >
                    {[{label: "Same Window" , value:"_self"}, {label: "New Window" , value:"_blank"}].map((item, i) => (
                        <MenuItem key={i} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </TextField>

            </div>
            {
                Array.isArray(messageVersion.destinations) && messageVersion.destinations.indexOf("push") !== -1 && <>
                <TextField
                    type="text"
                    label="Push Heading"
                    fullWidth
                    disabled={disableForm}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={msgData.pushHeading}
                    variant="outlined"
                    multiline
                    inputProps={{
                        style: {
                            minHeight: "150px"
                        },
                    }}
                    onChange={(e) =>  updateChanges(e.target.value , "pushHeading")}
                />
                <TextField
                    type="text"
                    label="Push Message"
                    fullWidth
                    disabled={disableForm}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={msgData.pushMessage}
                    variant="outlined"
                    multiline
                    inputProps={{
                        style: {
                            minHeight: "150px"
                        },
                    }}
                    onChange={(e) =>  updateChanges(e.target.value , "pushMessage")}
                />
                </>
            }
        </div>
    );
};

export default ContentForm;

