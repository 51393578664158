import React, {Fragment, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import {makeStyles} from "@material-ui/core/styles";
import TestMessage from "./TestMessage/TestMessage";

function PaperComponent(props: PaperProps) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper style={{minWidth:"600px"}} {...props} />
        </Draggable>
    );
}


const SettingsDialog
    : React.FC<{onModalClose: ()=> {} , modalOpen : boolean , name:string , id:string , templateId:string}>
    = (({onModalClose, modalOpen,name,id,templateId}) => {

    const handleClose = () => {
        onModalClose() ;
    };

    return (
        <div>
            <Dialog
                open={modalOpen}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{textAlign:"center",cursor: 'move'}} id="draggable-dialog-title">
                    {name}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div>
                            {
                                id === 'testMessage' && <TestMessage templateId={templateId}/>
                            }
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}) ;

const useStyles = makeStyles((theme) => ({

}));

export default SettingsDialog ;