export const enCountryCode = "en" ;
export const mobileAppScreens = ["changePassword","deposit","fnsScreen" , "uploadDocumentsStatus"]
export const languages = [
    {countryCode: enCountryCode , iconCode:"GB" , name : "EN"},
    {countryCode: "ar" , iconCode:"SA" ,name : "AR"},
    {countryCode: "de", iconCode:"DE" ,name : "DE"},
    {countryCode: "es" , iconCode:"ES" ,name : "ES"},
    {countryCode: "fr", iconCode:"FR" ,name : "FR"},
    {countryCode: "it" , iconCode:"IT" ,name : "IT"},
    {countryCode: "nl", iconCode:"NL" , name : "NL"},
    {countryCode: "no" , iconCode:"NO" ,name : "NO"},
    {countryCode: "pl" , iconCode:"PL" ,name : "PL"},
    {countryCode: "pt" , iconCode:"PT" ,name : "PT"},
    {countryCode: "sv", iconCode:"SE" , name : "SV"},
    {countryCode: "ru" , iconCode:"RU" ,name : "RU"},
    {countryCode: "cs" , iconCode:"CZ" ,name : "CS"},
    {countryCode: "el" , iconCode:"GR" ,name : "EL"},

] ;

export const TINY_MCE_API_KEY = 't65bqusgwhc5y7qps41167fv12zexp0dtqcy21uuht3ld5qq' ;