// ******* Auth Routs ********

export const LOGIN_ROUTE = '/login';

// ******* App Routs ********
export const DASHBOARD_ROUTE = '/dashboard';
export const ADD_MESSAGE_ROUTE = '/messages/new';
export const CATEGORIES_ROUTE = '/categories';
export const ADD_CATEGORY_ROUTE = '/categories/new';
export const EDIT_CATEGORY_ROUTE = '/categories/:id';
export const EDIT_MESSAGE_ROUTE = '/messages/:id';
export const MESSAGES_ROUTE = '/messages';
export const USERS_ROUTE = '/users';

export const CATEGORIES_MENU_MATCH = "/categories"
export const MESSAGES_MENU_MATCH = "/messages"
export const USERS_MENU_MATCH = "/users"







